<script>
    import {
        onMount,
        onDestroy,
        tick,
    } from 'svelte';
    import { getJson } from '@itslearning/atlas/network/fetchJson';

    import Avatar from '@itslearning/prometheus/assets/nodes/Avatar/v1/Avatar.svelte';
    import Banner from "@itslearning/prometheus/assets/feedback/Banner/v1/Banner.svelte";
    import Button from '@itslearning/prometheus/assets/inputs/Button/v1/Button.svelte';
    import LoadingSpinner from '@itslearning/prometheus/assets/nodes/LoadingSpinner/v1/LoadingSpinner.svelte';
    import Modal from '@itslearning/prometheus/assets/modals/Modal/v2/Modal.svelte';
    import Link from '@itslearning/prometheus/assets/Navigation/Link/v1/Link.svelte';
    import TabTrapHelper from '@itslearning/prometheus/lib/helpers/TabTrapHelper';
    import { formatLanguageString } from "@itslearning/atlas/strings/format.js";

    export let i18n;

    let enabled = false;
    let loading = false;
    let personId;
    let personData;
    let relations = [];

    let tabTrapper = undefined;
    let dialogElement;

    onMount(() => {
        // @ts-ignore
        window.newProfileCardSupported = true;
        document.addEventListener('openProfileCard', onOpenProfileCard);
    });

    onDestroy(() => {
        document.removeEventListener('openProfileCard', onOpenProfileCard);
        personData = undefined;
    });

    const getAvatarDescription = (person) => person.predefinedAvatarId === null
        ? person.pictureDescription
        : i18n.predefinedAvatars[`avatar${person.predefinedAvatarId}`];

    async function onOpenProfileCard(event) {
        enabled = loading = true;
        personId = event.detail.personId;

        try {
            const [
                personDataResponse,
                relationsResponse,
            ] = await Promise.all([
                getJson(`/restapi/profilecard/api/about/${personId}/v1`),
                getJson(`/restapi/persons/relations/${personId}`),
            ]);

            personData = {...personDataResponse, pictureDescription : getAvatarDescription(personDataResponse) };
            relations = relationsResponse;
        } finally {
            loading = false;

            dialogElement = document.querySelector(".prom-modal2__dialog");
            await tick();

            // Reset tab trapper when data have been populated
            if (dialogElement) {
                tabTrapper = new TabTrapHelper(dialogElement);
                tabTrapper.init();
            }
        }
    }
</script>

<Modal bind:enabled={enabled}>
    <div slot="body" class="profile-card-container" class:profile-card-container--loading={loading}>
        <LoadingSpinner label={i18n.loading} visible={loading} size="large" />

        {#if !loading && personData}

            <Avatar size={600} src={personData.pictureUrl} alternativeText={personData.pictureDescription} />

            <h2>{personData.personFullName}</h2>

            <div class="profile-card__privacy-protection">
                <Banner
                    type={'info'}
                    text={formatLanguageString(i18n.userPersonalInfoIsProtected, personData.personFullName)}
                    bind:visible={personData.isPrivacyProtectionEnabledForStaff}
                    closable={false}
                />
            </div>

            {#if personData.isEPortfolioEnabled && personData.ePortfolioUrl}
                <Link
                    href={personData.ePortfolioUrl}
                    kind="standalone"
                    iconClass="eportfolio-icon"
                    iconPlacement="end"
                    target="_blank"
                    text={i18n.viewEPortfolio}
                />
            {/if}

            <div class="profile-card__relationships">
                {#each relations as relation}
                    <p>
                        {relation.text}
                        {#if relation.items}
                            {#each relation.items as item, index}
                                <span>
                                    {item}{#if index !== relation.items.length - 1}{i18n.commaSeparator}{/if}
                                </span>
                            {/each}
                        {/if}
                    </p>
                {/each}
            </div>

            {#if personData.showExtraUserInfo}
                <div class="profile-card__extra-user-info">
                    <div class="profile-card__extra-user-info-label">{personData.extraUserInformationTitle}</div>
                    {#if personData.extraUserInformation}
                        <div class="profile-card__extra-user-info-value">{personData.extraUserInformation}</div>
                    {:else}
                        <div class="profile-card__extra-user-info-value">—</div>
                    {/if}
                </div>
            {/if}
        {/if}
    </div>

    <div slot="footer">
        <Link
            kind="primary-standalone"
            large={true}
            text={i18n.seeFullProfile}
            href={`/ProfilePage/Index?PersonId=${personId}`}
        />
        <Button type="secondary"
            htmlType="button"
            large={true}
            text={i18n.close}
            on:click={() => enabled = false} />
    </div>
</Modal>
